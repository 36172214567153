import './App.css';
import React, { useEffect } from 'react';
import flyingcat from "./img/svg.png";
import bgVideo from "./img/2611250-uhd_3840_2160_30fps.mp4";

function Main() {
  useEffect(() => {
    const messageElement = document.getElementById("message");
    setTimeout(() => {
      messageElement.style.opacity = 1;
    }, 500);
   
    const catElement = document.getElementById("cat");
    let position = 0;
    let direction = 1;

    function floatCat() {
      position += direction * 0.25;
      if (position > 20 || position < -20) {
        direction *= -1;
      }
      catElement.style.transform = `translate(-50%, calc(-50% + ${position}px))`;
      requestAnimationFrame(floatCat);
    }

    floatCat();
  }, []);

  return (
    <div id="app" style={{ position: 'relative', width: '100vw', height: '100vh', overflow: 'hidden' }}>
      <ul style={{ 
        listStyleType: 'none', 
        display: 'flex', 
        justifyContent: 'flex-end', 
        padding: '10px', 
        margin: 0, 
        position: 'absolute', 
        top: 0, 
        right: 0, 
        
        zIndex: 1,
      }}>
        <li style={{ margin: '0 15px', fontFamily: 'Piedra, sans-serif', fontSize: '20px' }}>
          <a href="#buy" target="_blank" style={{ textDecoration: 'none', color: '#FBF4A0', cursor: 'pointer'}}>BUY</a>
        </li>
        <li style={{ margin: '0 15px', fontFamily: 'Piedra, sans-serif', fontSize: '20px' }}>
          <a href="https://x.com/iothecatcoin" target="_blank" style={{ textDecoration: 'none', color: '#FBF4A0', cursor: 'pointer'}}>X</a>
        </li>
        <li style={{ margin: '0 15px', fontFamily: 'Piedra, sans-serif', fontSize: '20px' }}>
          <a href="https://t.me/iothecatcoin" target="_blank" style={{ textDecoration: 'none', color: '#FBF4A0', cursor: 'pointer'}}>TELEGRAM</a>
        </li>
      </ul>

      <video
        autoPlay
        loop
        muted
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          zIndex: -1,
        }}
      >
        
        <source src={bgVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      
      <div id="cat" style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
        <img src={flyingcat} alt="Floating Cat" id="floatingCat" />
      </div>
      <div id="message" style={{ position: 'absolute', top: '20%', left: '50%', transform: 'translateX(-50%)', fontFamily: '"Piedra", sans-serif', fontSize: '30px', color: '#FBF4A0', opacity: 0, transition: 'opacity 3s ease-in' }}>WELCOME FRIEND, LET'S FLY TOGHETER</div>
    </div>
  );
}

function App() {
  return (
    <div className="App">
      <Main />
      
    </div>
  );
}

export default App;
